var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "header__content mt-1"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.$vuetify.breakpoint.mdAndDown ? _c('v-app-bar-nav-icon', {
    on: {
      "click": _vm.handleSidebar
    }
  }) : _vm._e(), _vm.$vuetify.breakpoint.mdAndDown ? _c('Sidebar', {
    attrs: {
      "permanent": _vm.drawer,
      "absolute": true
    }
  }) : _vm._e(), _c('v-overlay', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.handleClickOutside,
      expression: "handleClickOutside"
    }],
    attrs: {
      "z-index": 15,
      "value": _vm.drawer
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-menu', {
    attrs: {
      "offset-y": "",
      "nudge-bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-avatar', _vm._g(_vm._b({
          attrs: {
            "dark": ""
          }
        }, 'v-avatar', attrs, false), on), [_c('img', {
          attrs: {
            "src": _vm.getProfileImageUrl || require('../../assets/images/CodeDrillsLight.svg')
          }
        })]), _c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v(_vm._s(_vm.mdiMenuDown))])];
      }
    }])
  }, [_c('v-list', [_c('v-list-item', {
    on: {
      "click": _vm.onClickProfile
    }
  }, [_c('v-list-item-title', [_c('div', {
    staticClass: "mr-5 menu_item text-uppercase subtitle",
    attrs: {
      "to": "#"
    }
  }, [_vm._v(" Profile ")])])], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-5 menu_item text-uppercase subtitle",
    attrs: {
      "to": "/community"
    }
  }, [_vm._v(" Community ")])], 1)], 1), _c('v-list-item', {
    on: {
      "click": _vm.confirmLogout
    }
  }, [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-5 menu_item text-uppercase subtitle",
    attrs: {
      "to": "#"
    }
  }, [_vm._v(" Logout ")])], 1)], 1)], 1)], 1)], 1), _c('Profile', {
    ref: "prof"
  }), _c('small-confirmation', {
    ref: "logout"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }