<template>
  <div class="d-flex flex-row">
    <div
      v-if="$vuetify.breakpoint.lgAndUp"
      class="ma-0 pa-0 fill-height"
      style="position: fixed"
    >
      <Sidebar v-if="$vuetify.breakpoint.lgAndUp" />
    </div>
    <div v-if="$vuetify.breakpoint.lgAndUp" style="flex-grow: 1"></div>
    <!-- spacer -->
    <div
      class="ma-0 pa-0"
      :class="{
        recruiter__content: $vuetify.breakpoint.lgAndUp,
        recruiter__small: $vuetify.breakpoint.mdAndDown,
      }"
    >
      <header class="mb-5">
        <Header />
      </header>
      <main class="mx-2 mb-16 pb-16">
        <slot />
      </main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Sidebar from "../components/recruiter/Sidebar.vue";
import Header from "../components/recruiter/Header.vue";

export default {
  name: "Recruiter",
  props: {
    loginRequired: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("recruiter", ["recruiter"]),
    ...mapState("customer", ["customer"]),
  },
  watch: {
    loginRequired: function () {
      this.checkUser();
    },
    user: function () {
      this.checkUser();
    },
  },
  methods: {
    ...mapActions("recruiter", ["fetchRecruiterProfile"]),
    ...mapActions("customer", ["fetchCustomer"]),
    checkUser() {
      if (!this.loginRequired) return;
      if (!this.user || this.user.isAnonymous)
        return this.$router.push("/login");

      if (!this.customer) {
        this.fetchCustomer()
          .then((r) => {
            console.log(`customer`, this.customer?.toObject());
          })
          .catch((err) => {
            if (err.message === "User not part of any customer.") {
              return this.$router.push("/community/dashboard");
            }
          });
      }
      if (!this.recruiter) {
        this.fetchRecruiterProfile((r) => {
          console.log(`recruiter`, this.recruiter?.toObject());
        });
      }
    },
  },
  mounted() {
    this.checkUser();
  },
  components: {
    Sidebar,
    Header,
  },
};
</script>
<style scoped>
.recruiter__content {
  width: calc(100% - 300px);
  display: flex;
  flex-direction: column;
}
.recruiter__small {
  width: 100%;
}
</style>
