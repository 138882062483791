var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-row"
  }, [_vm.$vuetify.breakpoint.lgAndUp ? _c('div', {
    staticClass: "ma-0 pa-0 fill-height",
    staticStyle: {
      "position": "fixed"
    }
  }, [_vm.$vuetify.breakpoint.lgAndUp ? _c('Sidebar') : _vm._e()], 1) : _vm._e(), _vm.$vuetify.breakpoint.lgAndUp ? _c('div', {
    staticStyle: {
      "flex-grow": "1"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "ma-0 pa-0",
    "class": {
      recruiter__content: _vm.$vuetify.breakpoint.lgAndUp,
      recruiter__small: _vm.$vuetify.breakpoint.mdAndDown
    }
  }, [_c('header', {
    staticClass: "mb-5"
  }, [_c('Header')], 1), _c('main', {
    staticClass: "mx-2 mb-16 pb-16"
  }, [_vm._t("default")], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }