<template>
  <v-row class="header__content mt-1">
    <v-col cols="6">
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndDown"
        @click="handleSidebar"
      ></v-app-bar-nav-icon>
      <Sidebar
        v-if="$vuetify.breakpoint.mdAndDown"
        :permanent="drawer"
        :absolute="true"
      />
      <v-overlay
        v-click-outside="handleClickOutside"
        :z-index="15"
        :value="drawer"
      ></v-overlay>
    </v-col>
    <v-col cols="6" class="d-flex justify-end">
      <!-- <v-avatar icon>
        <v-btn icon>
          <v-icon large>
            {{ mdiMoonWaningCrescent }}
          </v-icon>
        </v-btn>
      </v-avatar>
      <v-avatar icon>
        <v-btn icon>
          <v-icon large>
            {{ mdiBell }}
          </v-icon>
        </v-btn>
      </v-avatar> -->
      <div class="text-center">
        <v-menu offset-y nudge-bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar dark v-bind="attrs" v-on="on">
              <img
                :src="
                  getProfileImageUrl ||
                  require('../../assets/images/CodeDrillsLight.svg')
                "
              />
            </v-avatar>
            <v-icon v-bind="attrs" v-on="on">{{ mdiMenuDown }}</v-icon>
          </template>
          <v-list>
            <v-list-item @click="onClickProfile">
              <v-list-item-title>
                <div class="mr-5 menu_item text-uppercase subtitle" to="#">
                  Profile
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <router-link
                  class="mr-5 menu_item text-uppercase subtitle"
                  to="/community"
                >
                  Community
                </router-link>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="confirmLogout">
              <v-list-item-title>
                <router-link
                  class="mr-5 menu_item text-uppercase subtitle"
                  to="#"
                >
                  Logout
                </router-link>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <Profile ref="prof" />
      <small-confirmation ref="logout" />
    </v-col>
  </v-row>
</template>
<script>
import { mdiMenuDown, mdiBell, mdiMoonWaningCrescent } from "@mdi/js";
import Sidebar from "./Sidebar.vue";
import Profile from "./Profile.vue";
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";
import SmallConfirmation from "../assessment/SmallConfirmation.vue";
export default {
  data() {
    return {
      mdiMenuDown,
      mdiBell,
      mdiMoonWaningCrescent,
      drawer: false,
    };
  },
  components: {
    Profile,
    Sidebar,
    SmallConfirmation,
  },
  methods: {
    ...mapActions("recruiter", ["fetchRecruiterProfile"]),
    async confirmLogout() {
      if (
        await this.$refs.logout.open("Are you sure you want to log out?", {
          color: "primary",
        })
      ) {
        this.$router.push("/logout");
      }
    },
    onClickProfile() {
      this.$refs.prof.open();
    },
    handleClickOutside() {
      // console.log(`handleClickOutside`, this.drawer);
      this.drawer = false;
    },
    handleSidebar() {
      // console.log(`handleSidebar`, this.drawer);
      this.drawer = true;
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("recruiter", ["getProfileImageUrl"]),
    customerInitials() {
      return this.user?.displayName
        .split(" ")
        .map((val) => val[0].toUpperCase())
        .join("");
    },
  },
  created() {
    this.fetchRecruiterProfile();
  },
};
</script>
<style scoped>
.header__content {
  border-bottom: 2px solid #c4c4c4;
}
.menu_item {
  text-decoration: none;
  color: black;
}
</style>
