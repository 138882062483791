<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    class="non-overflow"
    @keydown.esc="!rejection && cancel"
  >
    <v-card class="px-12 py-5">
      <v-container class="d-flex">
        <v-card-text class="text-h2"> My Profile </v-card-text>
        <v-spacer> </v-spacer>
        <v-btn icon large @click.native="agree">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-container>
      <!-- 4 stats with pfp -->
      <v-container>
        <v-row no-gutters>
          <v-col cols="9">
            <v-row no-gutters>
              <v-col cols="12">
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="4" style="margin: auto">
                      <span class="font-weight-bold text-md-body-1">
                        Recruiter Name:
                      </span>
                    </v-col>
                    <v-col cols="8" class="average-font pl-1">
                      <div v-if="!editMode">
                        {{ name }}
                      </div>
                      <div v-else>
                        <v-text-field
                          counter
                          v-model="tempName"
                          :rules="nameRules"
                          v-if="editMode"
                        >
                        </v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <span class="font-weight-bold text-md-body-1">
                        Company Name:</span
                      >
                    </v-col>
                    <v-col cols="8" class="average-font pl-1">
                      {{ company }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <span class="font-weight-bold text-md-body-1">
                        Joining Date:</span
                      >
                    </v-col>
                    <v-col cols="8" class="average-font pl-1">
                      {{ date }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <span class="font-weight-bold text-md-body-1">
                        Role:</span
                      >
                    </v-col>
                    <v-col cols="8" class="average-font pl-1">
                      {{ role }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-container>
              <v-row no-gutters>
                <v-avatar
                  :size="200"
                  style="margin-left: auto; margin-right: auto"
                >
                  <Loader class="mr-14" v-if="imageEdit"></Loader>
                  <img
                    v-if="!imageEdit"
                    :src="
                      profileImageUrl
                        ? profileImageUrl
                        : require('./../../assets/images/icon/no_dp.png')
                    "
                  />
                </v-avatar>
              </v-row>
              <v-row class="justify-center">
                <input
                  v-if="editMode"
                  type="file"
                  ref="avatarFile"
                  style="display: none"
                  accept=".png, .jpg, .jpeg"
                  @change="uploadAttachmentSave"
                />
                <v-btn
                  v-if="editMode"
                  class="font-weight-bold justify-center red--text"
                  @click="openImage"
                  text
                  outlined
                >
                  Change Profile Photo
                </v-btn>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <!-- description -->
      <v-container>
        <v-card-text style="">
          <v-row no-gutters>
            <v-col cols="3">
              <span class="font-weight-bold text-md-body-1">
                Description:
              </span>
            </v-col>
            <v-col cols="9">
              <div v-if="!editMode">
                <span class="average-font">
                  {{ description }}
                </span>
              </div>
              <div v-if="editMode">
                <v-textarea
                  counter
                  v-if="editMode"
                  :outlined="editMode"
                  :rules="descRules"
                  v-model="tempDesc"
                >
                </v-textarea>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
      <v-card-actions v-show="!editMode" class="py-4 px-8 justify-end">
        <v-btn
          v-if="rejection"
          large
          outlined
          color="indigo"
          @click="onEdit"
          class="text-none text-subtitle-1 font-weight-medium px-2"
        >
          Edit
        </v-btn>
      </v-card-actions>
      <v-card-actions v-show="editMode" class="py-4 px-8 justify-center">
        <v-btn
          v-if="rejection"
          large
          outlined
          @click="onDiscard"
          class="text-none text-subtitle-1 font-weight-medium px-2"
        >
          Back (Discard change)
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          large
          :color="options.color"
          outlined
          @click="onSave"
          class="text-none text-subtitle-1 font-weight-medium px-2"
        >
          <span> Save </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { mdiClose } from "@mdi/js";

import Loader from "@/components/Loader.vue";

export default {
  props: {
    rejection: {
      default: true,
      required: false,
    },
  },
  data: () => ({
    nameRules: [(v) => v.length <= 50 || "Max 50 characters"],
    descRules: [(v) => v.length <= 400 || "Max 400 characters"],
    mdiClose: mdiClose,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    // title: null,
    options: {
      color: "primary",
      width: "1400px",
      zIndex: 200,
    },
    name: null,
    company: null,
    joinedOn: null,
    date: null,
    logoUrl: null,
    description: null,
    editMode: false,
    role: null,
    tempName: null,
    tempDesc: null,
    imageEdit: false,
  }),
  methods: {
    ...mapActions("recruiter", ["updateRecruiterProfile", "uploadUserImage"]),
    ...mapMutations("recruiter", [
      "setName",
      "setDescription",
      "setProfileImage",
      "resetProfileImageUrl",
    ]),
    open(options) {
      if (this.editMode) this.onDiscard();
      this.dialog = true;
      this.options = Object.assign(this.options, options);
      this.description = this.recruiter?.getAbout();
      this.joinedOn = new Date(this.recruiter?.getJoinedOn());
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      this.date = `${
        monthNames[this.joinedOn.getMonth()]
      } ${this.joinedOn.getDate()}, ${this.joinedOn.getFullYear()}`;
      const roleNum = this.adminRoles[this.recruiter.getRole()];
      this.resetProfileImageUrl();
      this.role = this.adminRolesPretty[roleNum];
      // this.profileImageUrl = this.profileImageUrl;
      this.name = this.recruiter.getName();
      this.company = this.customer.getName();
      if (!this.company || !this.company.length) {
        this.company = "Not set";
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      if (this.editMode) this.onDiscard();
      this.resolve(false);
      this.dialog = false;
      this.resetProfileImageUrl();
    },
    onEdit() {
      this.editMode = !this.editMode;
      this.tempName = this.name;
      this.tempDesc = this.description;
    },
    onSave() {
      if (this.tempName.length > 50 || this.tempDesc.length > 400) {
        return;
      }
      this.editMode = !this.editMode;
      this.name = this.tempName;
      this.description = this.tempDesc;

      this.setName(this.name);
      this.setDescription(this.description);
      this.recruiter.setImageUrl(this.profileImageUrl);
      this.updateRecruiterProfile();
    },
    onDiscard() {
      this.editMode = !this.editMode;
      this.resetProfileImageUrl();
    },
    openImage(event) {
      console.log("Event .. ", event);
      console.log(this.$refs.avatarFile);
      this.$refs.avatarFile.click();
    },
    async uploadAttachmentSave(event) {
      this.imageEdit = true;
      // console.log("files", await this.$refs.avatarFile.files[0].text());
      this.encodeImageFileAsURL(
        await this.$refs.avatarFile,
        this.uploadUserImage
      );
    },
    async encodeImageFileAsURL(element, callback) {
      var file = element.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        callback({ image: reader.result.replace(/^data:image.+;base64,/, "") });
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {
    ...mapState("recruiter", ["recruiter", "profileImageUrl"]),
    ...mapState("customer", ["customer"]),
    ...mapGetters("recruiter", ["adminRoles", "adminRolesPretty"]),
  },
  created() {
    // this.getRecruiterProfile();
  },
  watch: {
    profileImageUrl(i) {
      console.log("Profile Image Changed", i);
      // this.imageEdit = false;
      setTimeout(() => {
        this.imageEdit = false;
      }, 1500);
      // this.editProfile().then(_ => this.imageEdit = false);
    },
  },
  components: {
    Loader,
  },
};
</script>
<style scoped>
.flex-container {
  display: flex;
}
.img-circle {
  max-height: 200px;
  max-width: 200px;
  border-radius: 50%;
}
.red-b {
  background-color: red;
}
.average-font {
  font-size: 1.3em;
}
.non-overflow {
  overflow-y: auto;
}
.change-profile-text {
  color: red;
  font-size: 1.3em;
}
</style>
