<template>
  <v-navigation-drawer
    :permanent="permanent"
    :absolute="absolute"
    color="black"
    dark
    class="d-flex flex-column"
    style="z-index: 20"
    width="300px"
  >
    <v-list-item class="pa-0 ma-0 pt-5">
      <v-list-item-content class="pa-0 ma-0">
        <v-list-item-title class="text-h6">
          <router-link to="/dashboard">
            <v-img
              height="150px"
              :src="require('../../assets/images/RecruiterDash.png')"
            >
            </v-img>
          </router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item
        v-for="item in topItems"
        :key="item.title"
        link
        :to="item.to"
      >
        <v-list-item-icon class="mr-3">
          <v-icon right>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="pt-5">
        <v-list-item-icon class="mr-n3"></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-uppercase text-caption">
            system
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="item in systemItems"
        :key="item.title"
        link
        :to="item.to"
      >
        <v-list-item-icon class="mr-3">
          <v-icon right>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <v-sheet class="sheet-bg mx-5 mb-16 px-2" elevation="0" rounded="xl">
        <v-row class="" align="center" justify="center">
          <v-col cols="4">
            <v-avatar dark>
              <!-- <span class="white--text">
                {{ customerInitials ?? "" }}
              </span> -->
              <img
                class="px-1"
                v-if="!companyLogo"
                :src="require('../../assets/images/CodeDrills.svg')"
              />
              <img v-else :src="companyLogo" />
              <!-- @TODO: Add Image also -->
            </v-avatar>
          </v-col>
          <v-col class="d-flex flex-column">
            <span class="text-body-1 font-weight-medium">
              {{ companyName || companyDomain }}
            </span>
            <span class="text-subtitle-2"> {{ recruiterRole }} </span>
          </v-col>
        </v-row>
      </v-sheet>
    </template>
  </v-navigation-drawer>
</template>
<script>
import {
  mdiClipboardText,
  mdiCreditCardOutline,
  mdiAccount,
  mdiLabel,
  mdiForum,
  mdiAccountMultiple,
  mdiCog,
  mdiBriefcase,
} from "@mdi/js";
import { mapGetters, mapState } from "vuex";
export default {
  props: {
    permanent: {
      type: Boolean,
      default: true,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      topItems: [
        {
          title: "Assessment",
          icon: mdiClipboardText,
          to: "/assessments",
        },
        {
          title: "Jobs",
          icon: mdiBriefcase,
          to: "/jobs",
        },
        // {
        //   title: "Candidates",
        //   icon: mdiAccount,
        //   to: "/dashboard/candidates",
        // },
        // {
        //   title: "Skill Pool",
        //   icon: mdiLabel,
        //   to: "/dashboard/skillpool",
        // },
        {
          title: "Plan and Billing",
          icon: mdiCreditCardOutline,
          to: "/billing",
        },
      ],
      systemItems: [
        { title: "Help", icon: mdiForum, to: "/help" },
        {
          title: "Team",
          icon: mdiAccountMultiple,
          to: "/team",
        },
        {
          title: "Settings",
          icon: mdiCog,
          to: "/settings",
        },
      ],
      right: null,
      mdiClipboardText,
      mdiCreditCardOutline,
      mdiAccount,
      mdiLabel,
      mdiForum,
      mdiAccountMultiple,
      mdiCog,
      mdiBriefcase,
    };
  },
  computed: {
    ...mapState("customer", ["customer"]),
    ...mapState("recruiter", ["recruiter"]),
    ...mapGetters("recruiter", ["adminRoles", "adminRolesPretty"]),
    companyName() {
      return this.customer?.getName() ?? "Company";
    },
    companyDomain() {
      return this.customer?.getCompanyDomain();
    },
    recruiterRole() {
      return (
        this.adminRolesPretty[this.adminRoles[this.recruiter?.getRole()]] ??
        "Recruiter"
      );
    },
    companyLogo() {
      return this.customer?.getCompanyLogoUrl();
    },
  },
};
</script>
<style scoped>
.sheet-bg {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
