var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "z-index": "20"
    },
    attrs: {
      "permanent": _vm.permanent,
      "absolute": _vm.absolute,
      "color": "black",
      "dark": "",
      "width": "300px"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-sheet', {
          staticClass: "sheet-bg mx-5 mb-16 px-2",
          attrs: {
            "elevation": "0",
            "rounded": "xl"
          }
        }, [_c('v-row', {
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('v-avatar', {
          attrs: {
            "dark": ""
          }
        }, [!_vm.companyLogo ? _c('img', {
          staticClass: "px-1",
          attrs: {
            "src": require('../../assets/images/CodeDrills.svg')
          }
        }) : _c('img', {
          attrs: {
            "src": _vm.companyLogo
          }
        })])], 1), _c('v-col', {
          staticClass: "d-flex flex-column"
        }, [_c('span', {
          staticClass: "text-body-1 font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.companyName || _vm.companyDomain) + " ")]), _c('span', {
          staticClass: "text-subtitle-2"
        }, [_vm._v(" " + _vm._s(_vm.recruiterRole) + " ")])])], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('v-list-item', {
    staticClass: "pa-0 ma-0 pt-5"
  }, [_c('v-list-item-content', {
    staticClass: "pa-0 ma-0"
  }, [_c('v-list-item-title', {
    staticClass: "text-h6"
  }, [_c('router-link', {
    attrs: {
      "to": "/dashboard"
    }
  }, [_c('v-img', {
    attrs: {
      "height": "150px",
      "src": require('../../assets/images/RecruiterDash.png')
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-list', {
    attrs: {
      "dense": "",
      "nav": ""
    }
  }, [_vm._l(_vm.topItems, function (item) {
    return _c('v-list-item', {
      key: item.title,
      attrs: {
        "link": "",
        "to": item.to
      }
    }, [_c('v-list-item-icon', {
      staticClass: "mr-3"
    }, [_c('v-icon', {
      attrs: {
        "right": ""
      }
    }, [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.title))])], 1)], 1);
  }), _c('v-list-item', {
    staticClass: "pt-5"
  }, [_c('v-list-item-icon', {
    staticClass: "mr-n3"
  }), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-uppercase text-caption"
  }, [_vm._v(" system ")])], 1)], 1), _vm._l(_vm.systemItems, function (item) {
    return _c('v-list-item', {
      key: item.title,
      attrs: {
        "link": "",
        "to": item.to
      }
    }, [_c('v-list-item-icon', {
      staticClass: "mr-3"
    }, [_c('v-icon', {
      attrs: {
        "right": ""
      }
    }, [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.title))])], 1)], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }